.upgrade-prompt-container {
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 600px;
    margin: 2rem auto;
    text-align: center;
  }
  
  .upgrade-prompt-container h2 {
    color: #333;
    font-size: 1.8rem;
    margin-bottom: 1rem;
  }
  
  .upgrade-prompt-container p {
    color: #666;
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }
  
  .feature-list {
    text-align: left;
    margin-bottom: 1.5rem;
  }
  
  .feature-list h3 {
    color: #333;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .feature-list ul {
    list-style-type: none;
    padding-left: 0;
  }
  
  .feature-list li {
    color: #555;
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative;
  }
  
  .feature-list li::before {
    content: '✓';
    color: #28a745;
    position: absolute;
    left: 0;
  }
  
  .pricing-info {
    background-color: #e9ecef;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .pricing-info .price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #28a745;
  }
  
  .upgrade-button {
    background-color: #000000;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upgrade-button:hover {
    background-color: #0056b3;
  }