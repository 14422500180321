/* HomePage.css */

.home-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 85%;
    max-height: 100%;
    padding: 20px;
    background-color: #f5f6fa;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto auto;
    gap: 2%;
    width: 100%;
    height: 100%;
}

.grid-item {
    padding: 2%;
    border-radius: 8px;
}

.stats-top-left {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    display: flex;
    flex-direction: column;
    justify-content: center; /* Align items vertically in the center */
    align-items: center; /* Align items horizontally in the center */
    gap: 10px; /* Space between items */
}

.comments-section {
    grid-column: 2 / 3;
    grid-row: 1 / 2;
}

.banner {
    grid-column: 3 / 4;
    grid-row: 1 / 3; /* This takes up two rows */
}

.widget-info {
    grid-column: 1 / 2;
    grid-row: 2 / 3;
}

.progressed-circle {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Adjustments for other components should go here */
