.dashboard {
    display: flex;
    height: 100vh;
}

.dashboard-content {
    flex: 1;
    overflow-y: auto;
}

