.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it's above everything else */
  }
  
  .modal-container {
    background: #F0F0F0;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-width: 600px;
    position: relative;
    border-bottom: 2px solid #FFD600;
  }
  
  .close-button {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: none;
    font-size: 1.5rem; /* Adjust the size as needed */
    cursor: pointer;
    color: #333; /* Change the color if needed */
    padding: 0.5rem;
    line-height: 1; /* Removes extra height around the text */
    border-radius: 50%; /* Optional: if you want a circle around the 'X' */
    width: 30px; /* Optional: if you want a circle */
    height: 30px; /* Optional: if you want a circle */
    display: flex;
    align-items: center;
    justify-content: center;
  }  
  
  .price-table {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .price-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will ensure the button aligns to the bottom */
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 30%;
    margin-right: 5px;
    height: 100%; /* Make sure all cards have the same height */
    min-height: 300px;
  }
  
  
  .price-card.selected {
    background: black !important;
    color: white !important;
  }  
  
  .price-card:not(.selected) {
    background: #ffffff;
    color: black;
  }
  
  .price-card button {
    background-color: #FFD600;
    color: black;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    margin-top: 20px; /* Add space above the button */
  }  
  
  .plan-name {
    font-size: 1.25rem;
  }

  .plan-price {
    font-size: 2rem
  }

  .price-card ul {
    padding-left: 20px; /* Adjust the indent of the list */
    list-style: disc; /* If you want bullet points */
  }
  
  .price-card li {
    margin-bottom: 10px; /* Add more space between list items */
    font-size: 0.9rem; /* Optional: Adjust font size if needed */
  }
  