.demo-page {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.left-panel, .right-side-demo {
  width: 48%;
}

.textarea-container {
  position: relative;
  margin-bottom: 20px;
}

textarea {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.char-count {
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: 12px;
  color: #666;
}

.generate-button, .copy-button, .checkout-button {
  padding: 10px 20px;
  background-color: #000000;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.generate-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.embed-code-div {
  margin-top: 20px;
}

.embed-code-div textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.generated-history {
  margin-top: 30px;
}

.generated-history-dropdown {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.generated-history-item {
  margin-top: 20px;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  background-color: #f9f9f9;
}

.generated-history-item button {
  margin-top: 10px;
}
