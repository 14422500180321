/* LiveDemo.css */
.blog-post {
    max-width: 800px;
    margin: 0 auto;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    border-radius: 5px;
    overflow: hidden;
  }
  
  .blog-post-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  
  .blog-post-title {
    font-size: 24px;
    color: #333;
    margin-top: 0;
  }
  
  .audio-player {
    margin: 20px 0;
  }
  
  .blog-post-text h2 {
    font-size: 20px;
    color: #333;
    margin-top: 20px;
  }
  
  .blog-post-text p {
    font-size: 16px;
    color: #666;
    line-height: 1.5;
  }
  