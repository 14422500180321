.design-install-box {
    max-width: 600px;
    margin: auto;
    background-color: #F0F0F0;
    border-bottom: 2px solid #FFD600;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
    margin-top: 5%;
  }
  
  .platform-tabs {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
  }
  
  .tab {
    background: none;
    border: none;
    padding: 10px;
    color: black;
    cursor: pointer;
    font-family: 'Clash Display', sans-serif;
    display: flex;
    flex-direction: column; /* Stack icon and text vertically */
    align-items: center; /* Center-align children */
    font-weight: bold;
    text-align: center; /* Ensure text is centered under the icon */
  }
  
  .tab.active {
    color: #FFD600;
  }
  
  .platform-icon {
    height: 24px; /* Adjust the size as needed */
    margin-right: 8px; /* Space between icon and title */
  }
  
  .instruction-steps {
    background-color: #ffffff;
    padding: 20px;
    border-radius: 8px;
  }
  
  .instruction-steps h2 {
    margin-bottom: 10px;
  }
  
  .instruction-steps ol {
    padding-left: 20px;
  }

  .install-title {
    padding-top: 10px;
  }
  
  .embed-code-section {
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
  }
  
  .widget-copy-box {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: .8rem;
    contain: content;
    cursor: pointer;
    overflow: auto; /* Handle overflow */
    position: relative; /* Set position context for absolute positioning of children */
  }
  
  .copy-notification {
    color: green;
    text-align: center;
    margin-top: 10px;
  }  