.audio-player {
  width: 450px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #282828;
  border-radius: 100px;
  box-shadow: 0px -3px 4px 0px rgba(0, 0, 0, 0.25) inset, 
              0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  box-sizing: border-box;
  padding: 15px;
}

.audio-player__play-pause-btn {
  width: 45px;
  height: 45px;
  background-color: #282828;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  color: #ffe900;
}

.audio-player__progress-container {
  flex-grow: 1;
  height: 10px;
  background-color: #ffffff29;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
  margin-left: 15px;
}

.audio-player__progress-bar {
  height: 100%;
  background-color: #ffe900;
  border-radius: 5px;
  width: 0;
}

.audio-player__theme {
  width: 50px;
  height: 50px;
  background-color: #ffe900;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.audio-player__time {
  color: white;
  text-align: right;
  font-size: 16px;
  margin-left: 15px;
}

.widget-outline {
  width: 100%; 
  display: flex; 
  flex-direction: column; 
  align-items: center; 
}

.audio-player-widget {
  padding-top: 2%;
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  height: 100%; /* Take full height of the parent container */
  width: 100%;
  flex-direction: column; /* This will stack children vertically */
}

.loader-container {
  /* Your custom styles */
  display: flex;
  justify-content: center;
  align-items: center;
  /* other styles */
}

.svg-button {
  max-width: 150px;
  background: transparent;
}

.powered-by-logo {
  text-align: center; /* Centers the content */
  margin-top: 10px; /* Space above the link */
  color: black; /* Black text color */
}

.powered-by-logo a {
  color: black; /* Ensures the hyperlink is black */
  text-decoration: none; /* Removes underline from the hyperlink */
}

.powered-by-logo img {
  height: 20px; /* Adjust the size of the logo as needed */
  vertical-align: middle; /* Aligns the logo with the text */
}

.out-of-credits-container img {
  max-width: 150px;
  margin: 0 auto;
  display: block;
}


@media (max-width: 550px) {
  .audio-player {
    max-width: 95%;
    width: 350px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px;
    box-shadow: 0px -1px 2px 0px rgba(0, 0, 0, 0.25) inset, 
                0px 1px 2px 0px rgba(0, 0, 0, 0.25);
    position: relative;
    box-sizing: border-box;
    padding: 15px;
  }

  .audio-player__play-pause-btn {
    width: 28px; /* Adjusted size for a consistent circular shape */
    height: 28px; /* Equal width and height to ensure circle */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 16px; /* Adjusted font size for visibility */
    border-radius: 50%; /* Ensure circular shape */
    padding: 0; /* Remove padding to prevent distortion */
    border: none; /* Remove border to prevent distortion */
  }

  .audio-player__progress-container {
    flex-grow: 1;
    height: 7px;
    background-color: #ecf0f1;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    margin-left: 10px;
  }

  .audio-player__theme {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .audio-player__time {
    text-align: right;
    font-size: 12px;
    margin-left: 10px;
  }

  .svg-button {
    max-width: 100px;
  }

  .out-of-credits-container img {
    max-width: 100px;
  } 

}