
.total-plays {
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    border-radius: 100px; /* For rounded corners */
    border-bottom: 2px solid #FFD600; /* Red bottom border */
    background: #F0F0F0; /* White background */
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); /* Box shadow for depth */
    padding: 10px;
    width: 100%;
    height: 150px;

}

.total-plays-black {
    display: flex;
    justify-content: center; /* Center items horizontally */
    align-items: center; /* Center items vertically */
    border-radius: 100px; /* For rounded corners */
    border-bottom: 2px solid #FFD600; /* Red bottom border */
    background: #000000; /* White background */
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); /* Box shadow for depth */
    width: 100%;
    padding: 10px;
    height: 150px;
    color: white;
}



.stat-info {
    display: flex;
    flex-direction: row; /* Align children in a row */
    align-items: center; /* Align children vertically */
    justify-content: center; /* Center children horizontally */
    gap: 20px; /* Space between items */
    width: 100%; /* Full width of the parent */
}

.icon {
    width: 32px;    /* Adjust as needed */
    height: 32px;   /* Adjust as needed */
}

.stat-box h3, .stat-box p, .view-link {
    margin: 0; /* Remove default margins */
    display: inline; /* Inline display for the link */
}

/* Since .stacked-text will be in a row, we don't want it to be stacked */
.stacked-text .label,
.stacked-text .change {
    display: flex; /* Make them inline */
    margin-left: 5px; /* Add some space before the text */
}

.view-link {
    /* Add any specific styles for your link here */
    text-decoration: none; /* Example: remove underline from link */
    color: #007BFF; /* Example: default bootstrap link color */
}

.head-number {
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.icon {
    display: flex;
    width: 24px;
    height: 24px;
    justify-content: center;
    align-items: center;
}


/* Leaderboard.css */
.leaderboard-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Pushes footer to the bottom */
    height: 100%; /* Full viewport height */
    width: 100%;
    background-color: #F0F0F0;
    border-radius: 4px;
    border-bottom: 2px solid #FFD600; /* Red bottom border */
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    margin: 0; /* Ensure no margin outside the container */
    padding: 0; /* Ensure no padding inside the container that could push the content */
}

.tab-button {
    flex: 1;
    padding: 10px 0;
    font-size: 16px;
    color: #000; /* Text color */
    border: 1px solid #000; /* Outline for the button */
    background-color: transparent;
    font-family: 'Clash Display', sans-serif;    
    border-radius: 0%;
    cursor: pointer;
    outline: none;
}

.tab-button:not(:first-child) {
    border-left: none; /* Prevent double borders between buttons */
}

.tab-button:hover,
.tab-button.active {
    background-color: #FFFF99; /* Light yellow background on hover/active */
}


.leaderboard-title {
    color: #000;
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 20px; /* Adjust space below the title */
    padding-left: 10px;
}

.leaderboard-list {
    list-style: none;
    padding: 10px;
    margin: 0;
}

.leaderboard-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px; /* Space between items */
    border-radius: 100px;
    border: 1px solid #000; /* Black border */
    background-color: rgb(236, 236, 236);
    padding: 5px 5%;
}

.rank {
    font-size: 24px; /* Adjust as needed */
    font-weight: bold;
    margin-right: 10px;
}

.name {
    flex: 1;
    text-align: left;
}

.icon-change {
    height: 25px;
}

.leaderboard-footer {
    display: flex;
    justify-content: space-evenly; /* Distribute buttons evenly */
    width: 100%; /* Footer should span the full width of the container */
    height: 40px;
    background-color: #FFF; /* Background color */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}

.leaderboard-footer button:hover,
.leaderboard-footer button.active {
    background-color: #FFFF99; /* Light yellow background on hover/active */
}

.inside-content {
    flex: auto;
    padding-left: 10px;
}

/* Add styles for the rank circle */
.rank-circle {
    display: inline-block;
    width: 25px; /* Circle size */
    height: 25px;
    line-height: 25px; /* Center the text vertically */
    text-align: center; /* Center the text horizontally */
    background-color: #FFF;
    border-radius: 50%; /* Make it round */
    margin-right: 5px; /* Space before the name */
}

/* Embed.css */

.embed-container {
    width: 90%;
    padding: 20px;
    background-color: #F0F0F0;
    border-radius: 0px; /* Adjust if necessary */
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25); /* Box shadow for depth */
    border-bottom: 2px solid #FFD600; /* Red bottom border */
    min-height: 250px;

}

.embed-label {
    font-size: 18px; /* Adjust as needed */
    font-weight: bold;
    margin-bottom: 12px;
}

.embed-body {
    font-size: 14px; /* Adjust as needed */
    margin-bottom: 20px;
}

.embed-copy-box {
    display: flex;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: .8rem;
    contain: content;
    cursor: pointer;
    overflow: auto; /* Handle overflow */
    position: relative; /* Set position context for absolute positioning of children */
    max-width: 300px;
}

.embed-box {
    margin: 0;
}


.embed-code {
    display: inline-block;
    flex-grow: 1; /* Allows the span to expand */
    white-space: nowrap; /* Keeps the embed code in one line */
    overflow: hidden; /* Hides overflow */
    text-overflow: ellipsis; /* Adds an ellipsis to indicate hidden text */
}

.copy-icon {
    width: 24px;
    height: 24px;
    position: absolute; /* Absolute positioning */
    bottom: 4px; /* Top spacing */
    right: 4px; /* Right spacing */
    opacity: 50%;
}

.copy-notification {
    color: #4CAF50; /* Adjust as needed */
    font-size: 14px; /* Adjust as needed */
    margin-top: 10px;
    text-align: center;
}

/* CurrentPlan.css */

.current-plan-container {
    background-color: #F0F0F0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    width: 100%;
    margin: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    box-sizing: border-box;
    max-height: 290px;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-bottom: 2px solid #FFD600; /* Red bottom border */
}

.info-icon-container {
    position: relative;
    cursor: pointer;
}

.plan-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.plan-title {
    font-size: 1.5em;
    color: #333;
}

.info-icon-header {
    /* Adjust size and color as needed */
    width: 24px;
    height: 24px;
}

.info-icon {
    position: absolute;
    cursor: pointer;
    top: -45px;
    left: -10px;
    width: 24px;
    height: 24px;
}

.tooltip {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: white;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
}

.info-icon-container:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

.plan-details {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}

.label-limit {
    font-size: 1em;
    color: #333;
}

.amount {
    font-size: .75em;
    color: #777;
}

.progress-section {
    position: relative;
    display: flex;
    align-items: center;
}

.progress-svg {
    transform: rotate(-90deg);
}

.progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform-origin: center;
}

.visitors-count {
    position: absolute;
    font-size: 1em;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #333;
}
.upgrade-link {
    color: #FFC107;
    font-family: 'Clash Display', sans-serif;    
    text-decoration: none;
    align-self: flex-end; /* Align the link to the end of the flex container */
    text-align: right;
    background-color: transparent;
}


.progress-ring__background,
.progress-ring__circle {
    transition: stroke-dashoffset 0.35s;
    transform: rotate(-90deg);
    transform-origin: 50% 50%;
}

.upgrade-link:hover {
    text-decoration: underline;
}

/* Banner.css */


.banner-container {
    text-align: center;
    background-color: #000;
    color: #fff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    justify-content: center; /* Aligns children vertically */
    align-items: center; /* Aligns children horizontally */
    height: 100%; /* Sets the height of the banner to the full viewport height */
    box-sizing: border-box; /* Includes padding in the height calculation */
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    border-bottom: 2px solid #FFD600; 
}

.banner-text {
    font-size: 2em; /* Adjust size as needed */
    font-weight: 700; /* Montserrat bold */
    margin-bottom: 20px;
}

.butter-image {
    max-width: 90%;
    height: auto;
}