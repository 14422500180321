/* Styles for the main Custom page layout */
.Custom {
  display: flex;
  flex-direction: column;
  height: 90vh; /* Full viewport height */
  overflow: hidden; /* Prevent scrolling */
}
  
.playground {
  display: flex;
  flex: 1;
  overflow: hidden; /* Prevent scrolling in the playground area */
}
  
  .left-panel {
    flex: 2;
    padding:20px;
  }
  
  .right-panel {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: url('../assets/circles.png') center/cover, #fff5c7;
    padding: 20px;
    position: relative;
    overflow-y: auto; /* Enable vertical scrolling for the right panel */
  }
  

  .audio-and-design {
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }


  
  button {
    align-self: center;
    border: none;
    border-radius: 5px;
    background-color: #333;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #F0F0F0;
  }
  
  /* Styles for TopBar */
  .top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 10%;
    padding: 20px;
    border-bottom: 1px solid #000;
    box-sizing: border-box;  /* Added box-sizing */
  }

  .welcome-text {
    color: #000;
    font-size: 1rem;
    font-weight: 400;
  }

  .preset-dropdown {
    width: 190px;
    height: 40px;
    border-radius: 5px;
  }

  .save-button, .share-button {
    width: auto;
    height: 40px;
    background-color: #FFD600;
    color: black;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
    font-family: 'Clash Display', sans-serif;    
  }

  .controls {
    display: flex;
    gap: 10px;
    align-items: center; /* This will align the children vertically in the center */
  }


  
  /* Styles for InputArea */
  .input-area {
    padding: 20px;
    flex-direction: column;
    gap: 10px;
  }

  .body-label {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .heading-label {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .heading-input {
    width: 80%; /* Ensures the input takes full width */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  .body-text-input {
    width: 80%; /* Ensures the textarea takes full width */
    height: 200px; /* Adjust this value to your preference */
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    resize: vertical; 
    margin-top: 10px;
  }

/* Styles for control-panel */
.control-panel {
  border: 1px solid black;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;  /* Increased gap for spacing between controls */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0,0,0,0.1);  /* Adding a subtle shadow */
}

.control-panel label {
  display: flex;
  flex-direction: column;
  gap: 10px;  /* Space between label text and form element */
  font-weight: 500;  /* Slightly bolded labels for emphasis */
}

.control-panel select {
  padding: 10px;  /* Comfortable padding */
  border: 1px solid #e1e1e1;  /* Light gray border */
  border-radius: 5px;  /* Rounded corners */
  appearance: none;  /* Remove default appearance to ensure consistent look across browsers */
  background-color: #fff;  /* White background for the select box */
  cursor: pointer;  /* Change cursor to pointer on hover */
  font-size: 16px;  /* Increased font size for better readability */
}

.control-panel input[type="range"] {
  cursor: pointer;  /* Change cursor to pointer on hover */
}

/* Customize the range input thumb (the circle you drag) */
.control-panel input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  width: 25px;
  height: 25px;
  background: #ff66b2;  /* Pink color to match the image */
  border-radius: 50%;
  cursor: pointer;
}

.control-panel input[type="range"]::-moz-range-thumb {
  appearance: none;
  width: 25px;
  height: 25px;
  background: #ff66b2;
  border-radius: 50%;
  cursor: pointer;
}

/* Customize the range input track (the line the thumb slides on) */
.control-panel input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #ff66b2;  /* Pink color to match the image */
  border-radius: 4px;
}

.control-panel input[type="range"]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: #ff66b2;
  border-radius: 4px;
}

/* Ensure the range input has a consistent appearance across browsers */
.control-panel input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  margin: 6px 0;
}

.control-panel input[type="range"]:focus {
  outline: none;  /* Remove default browser focus style */
}

/* BlogDemo CSS. */


.mcdonalds-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.backdrop {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.content-container {
  padding: 20px;
}

.audio-demo {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* LiveDemo CSS. */
.live-view {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Design CSS. */
.design-component {
  max-width: 600px;
  margin: auto;
  background-color: #F0F0F0;
  border-bottom: 2px solid #FFD600;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-radius: 8px;
}

.design-component h2 {
  text-align: center;
  margin-bottom: 20px;
}

.questions {
  margin-bottom: 20px;
}

.question {
  display: flex;
  margin-bottom: 10px;
}

.question label {
  flex: 0 0 120px;
  line-height: 32px;
}

.color-boxes {
  display: flex;
  align-items: center;
}

.color-box {
  width: 50px;
  height: 25px;
  margin-right: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.color-picker-button {
  background: white;
  font-family: 'Clash Display', sans-serif;    
  border: 1px solid black;
  padding: 5px 10px;
  color: black;
  cursor: pointer;
}

.popover {
  position: absolute;
  z-index: 2;
}

.cover {
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.voice-options {
  border-radius: 70px;
  font-family: 'Clash Display', sans-serif;    
  width: 100px;
  padding: 10px;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Locked Section */
.design-component.restricted {
  background-color: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px; /* Adjust as necessary */
  width: 80%;
}

.upgrade-prompt {
  text-align: center;
}

.upgrade-button {
  background-color: #FFD600;
  color: black;
  font-family: 'Clash Display', sans-serif;    
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.question-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.question-buttons button {
  flex: 1;
  margin: 0 5px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s;
}

.question-buttons button:first-child {
  margin-left: 0;
}

.question-buttons button:last-child {
  margin-right: 0;
}

.question-buttons .test-button {
  background-color: #4CAF50;
  color: white;
}

.question-buttons .save-button {
  background-color: #008CBA;
  color: white;
}

.question-buttons button:hover {
  opacity: 0.8;
}

.question-buttons button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.save-status {
  text-align: center;
  margin-top: 10px;
  font-style: italic;
}
