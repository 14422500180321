/* SideNav.css */

.side-nav {
    width: 15%;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Ensure it fills the container */
}

.icon-logo-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 10%;
    padding-bottom: 15%;
}

.icon-logo-container .logo-link {
    display: flex; /* Apply flex to maintain container's flex layout */
    align-items: center;
    justify-content: center;
    width: 100%; /* Ensure it takes the full width of its container */
    height: auto; /* Or set a specific height if needed */
}

.nav-links {
    width: 100%;
    flex-grow: 1; /* Allows this section to grow */
}

.nav-link {
    display: flex;
    align-items: center;
    width: 100%;
    height: 30px;
    text-decoration: none;
    color: white; /* Adjust as needed */
    padding: 15px;
}

.nav-link span {
    margin-left: 15px; /* Increase space between icon and text */
    font-size: 16px; /* Adjust as needed */
}

.nav-link:hover, .nav-link.selected {
    background-color: rgba(255, 255, 255, 0.125);
}

.nav-icon {
    width: 30px;
    height: 30px;
}

.icon-logo {
    width: 60%;
}

.support-logout {
    width: 100%;
    padding-bottom: 20px;
}