.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 10%;
  border-bottom: 1px solid #e5e5e5;
  padding-left: .5%;  /* padding added to the left side */
  padding-right: 2.5%; /* padding added to the right side */
  position: sticky; /* This will make it stick to the top */
  z-index: 1000; /* Ensure the header stays on top of other content */
  top: 0; /* Align the header to the top */
  background-color: white; /* Specify a background color so content underneath doesn't show through */

}

.dashboard-title h2 {
  margin-left: 10px;
}

.dashboard-actions {
  display: flex;
  align-items: center;
  gap: 20px;  /* This adds space between all direct child elements of the flex container */
}

.profile-pic {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.help-icon {
  cursor: pointer;  /* Makes the help icon act like a clickable element */
}

.upgrade-btn {
  font-family: 'Clash Display', sans-serif;
  background-color: #FFD600;
  color: black;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}