.header-demo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 90%;
    padding: 10px 20px;
    background-color: black;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    border-radius: 50px;
    margin: auto;
    max-width: 1200px;
    position: relative;
  }

  .header__logo, .header__nav, .header__actions {
    display: flex;
    align-items: center;
  }
  
  .header__logo img {
    height: 30px;
    margin-left: 20px;
    flex-grow: 1;
  }

  
  
  .header__nav {
    flex-grow: 1; /* Allow the nav to take up available space */
    justify-content: flex-start; /* Align nav items to the start/left */
    gap: 20px; /* Maintain spacing between nav items */
  }

  .header__actions {
    margin-left: auto;
  }

  .nav__items {
    display: flex;
    margin-right: 0; /* Ensure nav items are aligned left */
    padding-left: 20px;
  }
  
  .nav__link, .action__link {
    text-decoration: none;
    color: white;
    font-weight: 700;
    padding-left: 10px;
    padding-right: 10px;
  }

  
  
  .header__actions {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .login__button {
    background-color: #ffe900;
    color: rgb(0, 0, 0);
    border: none;
    padding: 8px 15px;
    cursor: pointer;
    font-family: 'Clash Display', sans-serif;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 600;
  }
  
  .login__button:hover {
    background-color: #000000;
    color: white;
  }
  
  .nav-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .nav-toggle .hamburger {
    width: 30px;
    height: 2px;
    background: white;
    display: block;
    position: relative;
    transition: background 0s ease-in-out 0.3s;
  }
  
  .nav-toggle.toggled .hamburger {
    background: transparent;
  }
  
  .nav-toggle .hamburger::before,
  .nav-toggle .hamburger::after {
    content: '';
    background: white;
    width: 30px;
    height: 2px;
    display: block;
    position: absolute;
    transition: transform 0.3s ease-in-out;
  }
  
  .nav-toggle .hamburger::before {
    transform: translateY(8px) rotate(45deg);
  }
  
  .nav-toggle .hamburger::after {
    transform: translateY(-8px) rotate(-45deg);
  }
  
  .nav-toggle.toggled .hamburger::before {
    transform: rotate(-45deg);
  }
  
  .nav-toggle.toggled .hamburger::after {
    transform: rotate(45deg);
  }
  
  @media (max-width: 768px) {
    .header-demo {
      width: 80%;
      justify-content: space-between; /* Ensure logo and nav-toggle are on opposite sides */
      padding-left: 5%; /* Adjust padding to maintain 90% width appearance */
      padding-right: 5%;
    }
  
    .header__logo, .header__nav, .header__actions {
      width: auto; /* Reset any width constraints for mobile */
    }
  
    .nav-toggle {
      display: flex; /* Use flexbox to align child elements */
      justify-content: flex-start; /* Align children to the start (left) of the flex container */
      align-items: center; /* Center align the children vertically */
      margin-left: auto;
      position: relative;
      z-index: 10;
      width: 40px; /* Set a specific width */
      height: 40px; /* Set a specific height to ensure there's a hit area for the button */
      cursor: pointer; /* Makes it clear it's clickable */
  }
    
    .header__nav {
      display: none; /* Keep nav hidden until toggled */
      flex-direction: column;
      position: absolute;
      top: 100%;
      right: 0; /* Align to the right edge of the header */
      background-color: black;
      width: 100%; /* Ensure it spans the full width of the viewport */
      border-radius: 0 0 20px 20px; /* Adjust border-radius for design consistency */
      overflow: hidden;
      z-index: 5; /* Ensure dropdown is above other content but below the toggle for interaction */
    }
  
    .nav-visible {
      display: flex;
    }
  
    .nav__items, .header__actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  
    .nav__link, .action__link {
      width: 100%;
      padding: 10px;
      text-align: center;
    }
  
    .login__button {
      width: calc(100% - 40px); /* Adjust width considering padding */
    }
  }
  