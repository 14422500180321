  /* General Layout */
  .demo-full-page {
    padding-top: 10px;
    min-height: 100vh;
    background-image: linear-gradient(#fff5c7, #fcffe7);
  }

  .demo-page {
    display: flex;
    flex-direction: row;
    margin: 20px;
  }

  /* Left Panel */
  .left-panel {
    flex: 1;
    display: flex;
    flex-direction: column;
    max-width: 50vw;
    margin: 10px;
  }

  /* Right Panel */
  .right-side-demo {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 20px;
    position: relative;
    margin: 10px;
  }

  /* Input Areas */
  textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 20px;
  }

  /* Buttons */
  button {
    padding: 10px 20px;
    cursor: pointer;
  }

  /* Design Component */
  .design-demo-component {
    max-width: 600px;
    width: 90%;
    margin: 20px auto;
    background-color: #F0F0F0;
    border-bottom: 2px solid #FFD600;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    border-radius: 8px;
  }

  design-demo-component h2 {
    text-align: center;
    margin-bottom: 10px;
  }

  /* Questions in Design Component */
  .questions {
    margin-bottom: 10px;
  }

  .question {
    display: flex;
    margin-bottom: 10px;
  }

  .question label {
    flex: 0 0 120px;
    line-height: 32px;
  }

  /* Color Boxes */
  .color-boxes {
    display: flex;
    align-items: center;
  }

  .color-box {
    width: 50px;
    height: 25px;
    margin-right: 10px;
    border-radius: 5px;
    cursor: pointer;
  }

  /* Voice Options */
  .voice-options {
    border-radius: 70px;
    font-family: 'Clash Display', sans-serif;    
    width: 100px;
    padding: 10px;
  }

  /* Drop Zone */
  .drop-zone {
    border: 2px dashed #ccc;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .drop-zone.dragging {
    background-color: #f0f0f0;
    border-color: #999;
  }

  .drop-zone.has-file {
    background-color: #e6f7ff;
    border-color: #1890ff;
  }

  .money-icon {
    height: 16px;
    width: 16px;
    margin-left: 5px;
    vertical-align: middle;
  }

  /* Media Query for Responsive Design */
  @media (max-width: 768px) {
    .demo-page {
      flex-direction: column;
      margin: 10px;
    }

    .left-panel, .right-side-demo {
      max-width: 100%;
      margin: 10px 0;
    }

    .left-panel {
      order: 1;
    }

    .right-side-demo {
      order: 2;
      padding: 10px;
    }


    design-demo-component {
      padding: 10px;
      margin-top: 20px;
      padding-bottom: 5%;
    }

    .demo-full-page {
      height: 100%;
    }
  }

  .design-option {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }

  .design-option label {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
